exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-standerd-js": () => import("./../../../src/pages/about/standerd.js" /* webpackChunkName: "component---src-pages-about-standerd-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-life-style-index-js": () => import("./../../../src/pages/life-style/index.js" /* webpackChunkName: "component---src-pages-life-style-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-recipe-index-js": () => import("./../../../src/pages/recipe/index.js" /* webpackChunkName: "component---src-pages-recipe-index-js" */),
  "component---src-pages-special-feature-index-js": () => import("./../../../src/pages/special-feature/index.js" /* webpackChunkName: "component---src-pages-special-feature-index-js" */),
  "component---src-templates-lifestyle-archive-js": () => import("./../../../src/templates/lifestyleArchive.js" /* webpackChunkName: "component---src-templates-lifestyle-archive-js" */),
  "component---src-templates-lifestyle-post-js": () => import("./../../../src/templates/LifestylePost.js" /* webpackChunkName: "component---src-templates-lifestyle-post-js" */),
  "component---src-templates-recipe-archive-js": () => import("./../../../src/templates/recipeArchive.js" /* webpackChunkName: "component---src-templates-recipe-archive-js" */),
  "component---src-templates-recipe-post-js": () => import("./../../../src/templates/RecipePost.js" /* webpackChunkName: "component---src-templates-recipe-post-js" */),
  "component---src-templates-special-post-js": () => import("./../../../src/templates/SpecialPost.js" /* webpackChunkName: "component---src-templates-special-post-js" */)
}

